import { useTranslation } from "react-i18next";
import BreadCrumb from "../../../shared/utils/breadCrumb/BreadCrumb";
import SelectCatProduct from "../../components/selectCatProduct/SelectCatProduct";
import IconInv from "/icons/all/inventory/Current Stock.svg";

export default function AddInventory() {
  const { t } = useTranslation();
  return (
    <section className="Page__main_wrapper">
      <div className="Page__headline_wrapper">
        <div className="Page__headline">
          <img src={IconInv} className="Campaign__icon" />
          <h1 className="Page__headline_title_fs">
            {t("add_inventory")}{" "}
          </h1>
        </div>
        <BreadCrumb
          url1="/user/inventory"
          Link1={t("inventory")}
          Link2={`${t("add_inventory")}`}
        />
      </div>
      <SelectCatProduct
        type="inventory"
        addedOrderProducts=""
        setOrderAddedProducts=""
      />
    </section>
  );
}
