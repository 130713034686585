import React from "react";
import { Link } from "react-router-dom";
import RecallHistory from "./recallTables/RecallHistory";
import { useTranslation } from "react-i18next";

export default function InventoryRecall() {
  const { t } = useTranslation();
  const data = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];
  return (
    <React.Fragment>
      <section className="Page__main_wrapper">
        <div className="Page__action_wrapper">
          <div className="Page__left_actions">
            <div className="Page__headline">
              <i className="bx bx-box"></i>
              <h1 className="Page__headline_title_fs">
                {t("new_recall_inventory")}
              </h1>
            </div>
          </div>
        </div>
        <div className="Page__action_wrapper">
          <div className="Page__left_actions">
            <Link
              to="/user/inventory/recall/new"
              className="mi_btn mi_btn_medium mi_btn_primary"
            >
              <i className="fa-solid fa-plus"></i>
              <span>{t("create_recall")}</span>
            </Link>
          </div>
        </div>

        <div className="Page__table_space">
          <RecallHistory data={data} />
        </div>
      </section>
    </React.Fragment>
  );
}
