import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import StatusLabel from "../../../../shared/utils/statusLabel/StatusLabel";
import { TablePagination } from "@mui/material";
import { t } from "i18next";

export default function BatchTable({
  batches,
  page,
  setPage,
  limit,
  setLimit,
  pagination,
  category,
}) {
  const [checked, setChecked] = React.useState(true);
  const sorted = batches?.data?.sort(
    (a, b) => new Date(b.expDate) - new Date(a.expDate)
  );

  const isVaccine = category === "VACCINE" ? true : false;
  const isSyringe = category === "SYRINGE" ? true : false

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="mi_table__head">
            <TableRow>
              {/* <TableCell>
                <FieldHead title="Product Category" />
              </TableCell>
              <TableCell>
                <FieldHead title="Product Name" />
              </TableCell>
              <TableCell>
                <FieldHead title="Product ID" />
              </TableCell> */}
              <TableCell>
                <FieldHead title={t("batch_no")} />
              </TableCell>
              {/* <TableCell>
                <FieldHead title="Serial No." />
               </TableCell> */}
              <TableCell>
                <FieldHead title={t("manufacturer")} />
              </TableCell>
              <TableCell>
                <FieldHead title={t("mfg_date")} />
              </TableCell>
              {isSyringe && (
                <TableCell>
                  <FieldHead title={t("expiry_date")} />
                </TableCell>
              )}

              {isVaccine && (
                <TableCell>
                  <FieldHead title={t("expiry_date")} />
                </TableCell>
              )}

              {isVaccine && (
                <TableCell>
                  <FieldHead title={t("VVM_status")} />
                </TableCell>
              )}

              <TableCell>
                <FieldHead title={t("quantity")} />
              </TableCell>
              {isVaccine && (
                <TableCell>
                  <FieldHead title={t("no_of_vials")} />
                </TableCell>
              )}

              <TableCell>
                <FieldHead
                  title={isVaccine ? t("doses_per_vial") : t("quantity_per_unit")}
                />
              </TableCell>
              <TableCell>
                <FieldHead title={t("status")} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="mi_table__body">
            {sorted?.map((row, i) => {
         
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <FieldBody text={row.batchNo} />
                  </TableCell>
                  <TableCell>
                    <FieldBody text={row?.manufacturer?.name} />
                  </TableCell>

                  <TableCell>
                    <FieldBody
                      text={new Date(row?.mfgDate).toLocaleDateString()}
                    />
                  </TableCell>
                  {isSyringe && (
                    <TableCell>
                      {row?.expDate ? (
                        <FieldBody
                          subtext={new Date(row?.expDate).toLocaleDateString()}
                          color="red"
                        />
                      ) : (
                        <FieldBody subtext={"- - -"} />
                      )}
                    </TableCell>
                  )}
                  
                  {isVaccine && (
                    <TableCell>
                      {row?.expDate ? (
                        <FieldBody
                          subtext={new Date(row?.expDate).toLocaleDateString()}
                          color="red"
                        />
                      ) : (
                        <FieldBody subtext={"- - -"} />
                      )}
                    </TableCell>
                  )}


                  {isVaccine && (
                    <TableCell>
                      {row?.vvmStatus ? (
                        <FieldBody text={row?.vvmStatus} />
                      ) : (
                        <FieldBody subtext={"- - -"} />
                      )}
                    </TableCell>
                  )}

                  <TableCell>
                    <FieldBody
                      text={row.quantity + " " + batches?.product?.units}
                    />
                  </TableCell>
                  {isVaccine && (
                    <TableCell>
                      <FieldBody text={row?.noOfUnits} />
                    </TableCell>
                  )}

                  <TableCell>
                    <FieldBody text={row?.quantityPerUnit} />
                  </TableCell>
                  <TableCell>
                    <StatusLabel status={row?.status} tone="grey" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={pagination?.totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}
