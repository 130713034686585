import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ColdChainTable from './coldChainTable/ColdChainTable';
import './ColdChain.css';
import { useTranslation } from 'react-i18next';

import Filter from '/icons/all/Filter.svg';
import Import from '/icons/all/Import.svg';
import Search from '/icons/all/Search.svg';
import ColdChain1 from '/icons/all/Cold Chain.svg';
import {
  getAssetAnalytics,
  getGapAnalytics,
} from '../../../services/coldchain-services/api';
// import AnalyticCard from "../../../shared/utils/analyticCard/AnalyticCard";
import { formatNumber } from '../../../shared/common/commonFunctions';
import AnalyticCard from '../../../shared/utils/analyticCard/AnalyticCard';
import PermissionRoute from '../../../web/routes/routeLayers/PermissionRoute';
import { useGetAssetsAnalytics } from '../../../services/coldchain-services/queries';

export default function ColdChain() {
  const { t } = useTranslation();
  const [totalCapacity, setTotalCapacity] = useState();
  const [usedCapacity, setUsedCapacity] = useState();

  const { data: assetAnalytics } = useGetAssetsAnalytics();


  useEffect(() => {
    async function fetchData() {
      const { data } = await getGapAnalytics();
      setTotalCapacity(data?.totalVolume?.totalCapacity);
      setUsedCapacity(data?.currentVolume?.totalUsed);
    }
    fetchData();
  }, []);
  return (
    <section className='Page__main_wrapper'>
      {/* <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img src={ColdChain1} className='Campaign__icon' />
          <h1 className='Page__headline_title_fs'>
            {t('cold_chain_monitoring')}
          </h1>
        </div>
      </div> */}
      <div className='Page__action_wrapper'>
        <div className='Page__left_actions'>
          {/* <Link
            to="/user/shipments/add-shipments"
            className="mi_btn mi_btn_medium mi_btn_primary"
          >
            <i className="fa-solid fa-plus"></i>
            <span>Add</span>
          </Link> */}
          <Link
            to='/user/cold-chain/add/assets'
            className='mi_btn mi_btn_medium mi_btn_primary'
          >
            <i className='fa-solid fa-plus'></i>
            <span>{t('add') + ' ' + t('asset')}</span>
          </Link>
          <Link
            to='/user/maintenance'
            className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
          >
            <i className='fa-solid fa-plus'></i>
            <span>{t('maintenance')}</span>
          </Link>
          <PermissionRoute allowed={'VIEW_COLDCHAIN'} type='actions'>
            <Link
              to='/user/assets-transfer'
              state={{ transfer: true }}
              className='mi_btn mi_btn_medium mi_btn_secondary bg_green'
            >
              <i className='fa-solid fa-right-left'></i>
              <span>{t('asset_transfer')}</span>
            </Link>
          </PermissionRoute>
          <button className='mi_btn mi_btn_medium mi_btn_outline'>
            {/* <i className="fa-solid fa-download"></i> */}
            <img src={Import} alt='' style={{ height: '18px' }} />
            <span>{t('import')}</span>
          </button>
        </div>
        <div className='Page__right_actions'>
          <article className='mi_search_bar'>
            <img src={Search} className='Search__icon' />
            <input type='text' placeholder={t('search')} />
          </article>
          <button className='mi_btn mi_btn_medium mi_btn_outline'>
            {/* <i className="fa-solid fa-sliders"></i> */}
            <img src={Filter} alt='' style={{ height: '18px' }} />
            <span>{t('filter')}</span>
          </button>
        </div>
      </div>

      <div className='Page__Analytic_list'>
        <AnalyticCard
          title={t('Total Assets')}
          number={formatNumber(assetAnalytics?.data?.totalAssets)}
          labelType='blue_label'
          nonClickable={true}
        />
        <AnalyticCard
          title={t('Working')}
          number={formatNumber(assetAnalytics?.data?.workingAssets)}
          labelType='green_label'
          nonClickable={true}
        />
        <AnalyticCard
          title={t('Repairable')}
          number={formatNumber(assetAnalytics?.data?.repairableAssets)}
          nonClickable={true}
        />
        <AnalyticCard
          title={t('Non Repairable')}
          number={formatNumber(assetAnalytics?.data?.notRepairableAssets)}
          nonClickable={true}
        />
        <AnalyticCard
          title={t('Maintenance')}
          number={formatNumber(assetAnalytics?.data?.maintenanceAssets)}
          nonClickable={true}
        />
        <AnalyticCard
          title={t('Not Working')}
          number={formatNumber(assetAnalytics?.data?.notWorkingAssets)}
          nonClickable={true}
        />
      </div>

      <div className='ColdChainTable__header'>
        <div className='cold_container'>
          <div className={`TabItem__card `}>
            <h1>{t('Annual Capacity')} </h1>

            <div className='tab_analytics'>
              <span>{totalCapacity ? totalCapacity + ' litre' : '--'}</span>
            </div>
          </div>
          <div className='total'>
            <div className={`TabItem__card `}>
              <h1>{t('Total Capacity')} </h1>

              <div className='tab_analytics'>
                <span>{totalCapacity ? totalCapacity + ' litre' : '--'}</span>
              </div>
            </div>
          </div>
          <div className='current'>
            <div className={`TabItem__card `}>
              <h1>{t('Current Capacity')} </h1>

              <div className='tab_analytics'>
                <span>
                  {totalCapacity && usedCapacity
                    ? usedCapacity / 1000 + ' litre'
                    : '--'}
                </span>
              </div>
            </div>
          </div>
          <div className='gap'>
            {' '}
            <div className={`TabItem__card `}>
              <h1>{t('GAP')} </h1>

              <div className='tab_analytics'>
                <span>
                  {totalCapacity && usedCapacity
                    ? totalCapacity - usedCapacity / 1000 + ' litre'
                    : '--'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='Page__table_wrapper'>
        <ColdChainTable t={t} />
      </div>
    </section>
  );
}
