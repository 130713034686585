import React from 'react';
import { getTripInfo } from '../../../redux/shipmentActions';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import TripSummaryMap from './TripSummaryMap';
import GeoTracking from '/icons/all/geo-tracking.svg';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../../shared/utils/statusLabel/StatusLabel';
import TemperatureModal from '../transportRoute/tempModal/TemperatureModal';

export default function ViewTrip() {
  const { t } = useTranslation();
  const { id: tripId } = useParams();
  const { data: shipInfo } = useQuery({
    queryKey: ['tripInfo', tripId],
    queryFn: () => getTripInfo(tripId),
    enabled: !!tripId,
  });

  const [openBatchPopup, setOpenBatchPopup] = React.useState(false);

  const handleOpenBatchPopup = (id) => {
    setOpenBatchPopup(true);
  };

  const handleCloseBatchPopup = () => {
    setOpenBatchPopup(false);
  };

  return (
    <section className='Page__main_wrapper'>
      <div className='Page__headline_wrapper'>
        <div className='Page__headline'>
          <img
            src={GeoTracking}
            className='Lastmile__icon'
            alt='Last Mile Icon'
          />
          <h1 className='Page__headline_title_fs'>View Trip</h1>
        </div>
      </div>

      <div className='trip_summary_container'>
        <div className='trip_summary_column'>
          <div className='Order__summary_card'>
            <div className='Order__summary_header'>
              <div className='Order__flex'>
                <h1 className='page__body_heading_fs'>
                  {t('transit_no')} - {shipInfo?.tripInfo?.transitNo}
                </h1>
              </div>
              <StatusLabel
                status={shipInfo?.tripInfo?.status}
                tone={
                  shipInfo?.tripInfo?.status === 'COMPLETED' ? 'green' : 'blue'
                }
              />
            </div>
            <div className='Order__summary_body'>
              <article className='Order__summary_body_list'>
                <div className='summary_body_content_card'>
                  <h1 className='page__body_fs mi_bold'>{t('shipments')} ID</h1>
                  <h1 className='page__notion'>:</h1>
                  <p className='page__body_fs '>{shipInfo?.tripInfo?._id}</p>
                </div>
                <div className='summary_body_content_card'>
                  <h1 className='page__body_fs mi_bold'>
                    {t('Trip completed at')}
                  </h1>
                  <h1 className='page__notion'>:</h1>
                  <p className='page__body_fs '>
                    {new Date(
                      shipInfo?.tripInfo?.updatedAt
                    ).toLocaleDateString()}{' '}
                    &{' '}
                    {new Date(
                      shipInfo?.tripInfo?.updatedAt
                    ).toLocaleTimeString()}
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div className='trip_summary_column'>
          {shipInfo?.tripInfo?.shipments?.map((shipment, i) => (
            <div className='trip__info_card' key={shipment?.location?.name}>
              <div className='trip__info_details'>
                <p className='page__note_fs'>
                  <strong>Supply {i + 1} : </strong>
                  {shipment?.location?.postalAddress}
                </p>
                <p className='page__gray_fs'>
                  Delivered at:{' '}
                  {new Date(
                    shipment?.deliveredAt?.timestamp
                  ).toLocaleDateString()}{' '}
                  &{' '}
                  {new Date(
                    shipment?.deliveredAt?.timestamp
                  ).toLocaleTimeString()}
                </p>
              </div>

              <div className='trip__info_action'>
                <StatusLabel
                  status={shipment?.isDelivered ? 'Delivered' : 'Active'}
                  tone={shipment?.isDelivered ? 'green' : 'blue'}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='geo__map_view'>
        <div className='ShipmentMap__container'>
          <TripSummaryMap
            tripInfo={shipInfo?.tripInfo}
            locationTrace={shipInfo?.locationTrace}
          />
        </div>

        <div className='current_temp_btn'>
          <button
            className='mi_btn mi_btn_small mi_btn_primary'
            onClick={handleOpenBatchPopup}
          >
            Current Temperature:{' '}
            {shipInfo?.tripInfo?.currentLocation?.temperature
              ? `${shipInfo?.tripInfo?.currentLocation?.temperature} C`
              : 'NA'}
          </button>
        </div>
      </div>

      <TemperatureModal
        keepMounted
        open={openBatchPopup}
        onClose={handleCloseBatchPopup}
        allTemperatures={shipInfo?.temperatureTrace}
        currentTemperature={shipInfo?.tripInfo?.currentLocation?.temperature}
      />
    </section>
  );
}
